.mb-050 {
    margin-bottom: 0.5rem!important;
}
.mb-075 {
    margin-bottom: 0.75rem!important;
}
.mb-100 {
    margin-bottom: 1rem!important;
}
.mb-125 {
    margin-bottom: 1.25rem!important;
}
.mb-225 {
    margin-bottom: 2.25rem!important;
}
.mt-050 {
    margin-top: 0.5rem!important;
}
.mt-075 {
    margin-top: 0.75rem!important;
}
.mt-100 {
    margin-top: 1rem!important;
}
.mt-125 {
    margin-top: 1.25rem!important;
}
.pr-125 {
    padding-right: 1.25rem!important;
}