// Sizes
.heading-1 {
    font-family: $font-geometria;
    font-size: 40px;
    font-weight: 300;
    letter-spacing: -3px;
    line-height: 44px;
}

.heading-2 {
    color: $color-dark;
    font-family: $font-geometria;
    font-size: 26px;
    font-weight: 300;
    letter-spacing: -0.3px;
    line-height: 30px;

    strong {
        font-weight: 500;
    }

    
    @media screen and (min-width: $breakpoint-min-tablet) and (max-width: ($breakpoint-min-desktop - 1px)) {
        font-size: 36px;
        line-height: 40px;
        letter-spacing: -2px;
    }
    
    @media screen and (min-width: $breakpoint-min-desktop) {
        font-size: 40px;
        line-height: 44px;
        letter-spacing: -3px;
    }
}

.heading-3 {
    font-family: $font-geometria;
    font-size: 24px;
    strong {
        font-weight: 500;
    }
    letter-spacing: -0.4px;
    line-height: 30px;
    text-align: center;

    @media screen and (min-width: $breakpoint-min-tablet) and (max-width: ($breakpoint-min-desktop - 1px)) {
        font-size: 28px;
        line-height: 32px;
        letter-spacing: -1px;
    }
    
    @media screen and (min-width: $breakpoint-min-desktop) {
        font-size: 32px;
        line-height: 36px;
        letter-spacing: -1px;
    }
}

.heading-4 {
    font-family: $font-geometria;
    strong {
        font-weight: 500;
    }
    font-size: 20px;
    letter-spacing: -0.4px;
    line-height: 25px;
    text-align: center;
}

.heading-5 {
    font-family: $font-montserrat;
    font-size: 14px;
    letter-spacing: -0.4px;
    line-height: 18px;

    strong {
        font-weight: 500;
    }
}

.text-1 {
    display: block;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;
    font-family: 'Montserrat';
    font-weight: 400;
    color: $color-dark;
    text-align: center;

    strong {
        font-weight: 600;
    }

    @media screen and (min-width: $breakpoint-min-tablet) {
        font-size: 18px;
        line-height: 28px;
    }
}

.text-2 {
    display: block;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.1px;
    font-family: 'Montserrat';
    font-weight: 400;
    color: $color-dark;

    strong {
        font-weight: 600;
    }

    @media screen and (min-width: $breakpoint-min-tablet) {
        font-size: 18px;
        line-height: 28px;
    }
}

.text-3 {
    display: block;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.1px;
    font-family: 'Montserrat';
    font-weight: 400;
    color: $color-dark;

    strong {
        font-weight: 600;
    }

    // @media screen and (min-width: $breakpoint-min-tablet) {
    //     font-size: 18px;
    //     line-height: 28px;
    // }
}

// Weights
.weight-regular{
    font-weight: normal;
}
.weight-bold{
    font-weight: bold;
}
.weight-extra_bold{
    font-weight: bold;
}
.weight-extra_light{
    font-weight: 200;
}
.weight-heavy{
    font-weight: 900;
}
.weight-light{
    font-weight: 300;
}
.weight-medium2{
    font-weight: 600;
}
.weight-medium{
    font-weight: 500;
}
.weight-thin{
    font-weight: 100;
}

// Colors
.color-green {
    color: $color-green;
}
.color-blue {
    color: $color-blue;
}
.color-light {
    color: $color-light;
}
.color-dark {
    color: $color-dark;
}

// Misc
.no-text-decoration {
    text-decoration: none;
}

.text-left { text-align: left; }
.text-center { text-align: center; }
.text-right { text-align: right; }


ul.list {
    margin-bottom: 24px;
    li {
        display: block;
        position: relative;
        padding-left: 24px;
        margin-bottom: 22px;
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 4px;
            left: 3px;
            width: 10px;
            height: 9px;
            background-image: url(../../assets/icon/list-check.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
        
        color: $color-dark;
        font-family: Montserrat;
        font-size: 14px;
        letter-spacing: -0.1px;
        line-height: 20px;
        strong {
            font-weight: 600;
        }
    }

    @media screen and (min-width: $breakpoint-min-tablet) {
        margin-bottom: 20px;
        li {
            padding-left: 32px;
            margin-bottom: 12px;
            font-size: 16px;
            line-height: 24px;
            &:before {
                top: 4px;
                left: 6px;
                width: 15px;
                height: 12px;
            }
        }
    }

    @media screen and (min-width: $breakpoint-min-desktop) {
        margin-bottom: 28px;
        li {
            padding-left: 32px;
            margin-bottom: 12px;
            font-size: 16px;
            line-height: 24px;
            &:before {
                top: 4px;
                left: 6px;
                width: 15px;
                height: 12px;
            }
        }
    }
}

// Responsive classes
@media screen and (max-width: ($breakpoint-min-tablet - 1px)) {
    span.break--mobile {display: block;}
    .text-left--mobile {text-align: left!important;}
    .text-center--mobile {text-align: center!important;}
    .text-right--mobile {text-align: right!important;}
}

@media screen and (min-width: $breakpoint-min-tablet) and (max-width: ($breakpoint-min-desktop - 1px)) {
    span.break--tablet {display: block;}
    .text-left--tablet {text-align: left!important;}
    .text-center--tablet {text-align: center!important;}
    .text-right--tablet {text-align: right!important;}
}

@media screen and (min-width: $breakpoint-min-desktop) {
    span.break--desktop {display: block;}
    .text-left--desktop {text-align: left!important;}
    .text-center--desktop {text-align: center!important;}
    .text-right--desktop {text-align: right!important;}
}