
*, *:before, *:after, *::before, *::after {
    box-sizing: border-box;
}

html, body {
    height: 100%;
}

[hidden] { display: none !important;}


.debug {
    padding: 4px;
    border: 2px dashed yellow;
    background-color: grey;
    position: fixed;
    opacity: 0.5;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 99999;
}