.ng-option {
    span {
        color: $color-dark;
        font-family: Geometria;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.1px;
    }
}

.form {
    label {
        display: block;
        position: relative;

        > span {
            display: block;
            font-family: 'Geometria';
            color: $color-gray-dark;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 8px;
            letter-spacing: -0.1px;
            width: 100%;

            @media screen and (min-width: $breakpoint-min-tablet) {
                font-size: 16px;
                line-height: 20px;
            }
        }
    
        input.input {
            display: block;
            border: 1px solid $grey-3;
            border-radius: 4px;
            background-color: #fff;
            height: 44px;
            width: 100%;
            padding: 0 8px;
            color: $color-dark;
            font-family: Geometria;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: -0.1px;
            
            @media screen and (min-width: $breakpoint-min-tablet) {
                height: 48px;
            }
        }

        .ng-select.input {
            display: block;

            .ng-select-container {
                height: 44px;
                width: 100%;
                border: 1px solid $grey-3;
                border-radius: 4px;
                
                @media screen and (min-width: $breakpoint-min-tablet) {
                    height: 48px;
                }
            }

            .ng-placeholder {
                color: #B7BABC;
                font-family: Geometria;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: -0.1px;
                line-height: 18px;
            }

            .ng-input {
                top: 50%!important;
                transform: translateY(-50%);
            }

            span {
                margin-bottom: 0!important;
            }

            .ng-value-label {
                color: $color-dark;
                font-family: Geometria;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: -0.1px;
            }

            .ng-arrow-wrapper {
                padding-right: 20px;
                box-sizing: content-box;
                text-align: right;
                .ng-arrow {
                    width: 16px;
                    height: 16px;
                    border: none;
                    top: 4px;
                    margin-left: auto;
                    margin-right: 0;
                    left: unset;
                    background-image: url(../../assets/icon/form/select-caret.svg);
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }

            &.ng-select-opened {
                .ng-arrow {
                    transform: rotate(-180deg);
                }
            }
        }

        &.checkbox {
            position: relative;
            padding-left: 24px;

            &:after{
                position:absolute; width:0; height:0; overflow:hidden; z-index:-1;
                content:url(../../assets/icon/form/checkbox-off.svg) url(../../assets/icon/form/checkbox-on.svg) url(../../assets/icon/form/checkbox-error.svg);
            }

            input[type=checkbox] {
                position: absolute;
                top: 0;
                left: 0;
                width: 1px;
                height: 1px;
                opacity: 0;
                visibility: hidden;
                z-index: -1;
            }
            input[type=checkbox] + span.checkbox {
                display: block;
                width: 16px;
                height: 16px;
                top: 0;
                left: 0;
                position: absolute;
                background-image: url(../../assets/icon/form/checkbox-off.svg);
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
            }
            input[type=checkbox]:checked + span.checkbox {
                background-image: url(../../assets/icon/form/checkbox-on.svg);

            }

            p {
                color: $color-dark;
                font-family: Montserrat;
                font-size: 12px;
                letter-spacing: 0;
                line-height: 18px;
            }
        }

        &.radio {
            position: relative;
            padding-left: 24px;
            
            &:after{
                position:absolute; width:0; height:0; overflow:hidden; z-index:-1;
                content:url(../../assets/icon/form/radio-off.svg) url(../../assets/icon/form/radio-on.svg) url(../../assets/icon/form/radio-error.svg);
            }


            input[type=radio] {
                position: absolute;
                top: 0;
                left: 0;
                width: 1px;
                height: 1px;
                opacity: 0;
                visibility: hidden;
                z-index: -1;
            }
            input[type=radio] + span.radio {
                display: block;
                width: 16px;
                height: 16px;
                top: 0;
                left: 0;
                position: absolute;
                background-image: url(../../assets/icon/form/radio-off.svg);
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
            }
            input[type=radio]:checked + span.radio {
                background-image: url(../../assets/icon/form/radio-on.svg);

            }

            p {
                color: $color-dark;
                font-family: Montserrat;
                font-size: 12px;
                letter-spacing: 0;
                line-height: 18px;
            }
        }

        &.big-switch {
            position: relative;
            padding-right: 72px;
            input[type=checkbox] {
                display: block;
                width: 1px;
                height: 1px;
                overflow: hidden;
                opacity: 0;
                visibility: hidden;
                position: absolute;
                top: 0;
                left: 0;

                + span.switch {
                    display: block;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 60px;
                    height: 30px;
                    background-color: $grey-4;
                    border-radius: 60px;
                    transition: background-color 0.2s;;
                    &:before {
                        content: '';
                        display: block;
                        width: 28px;
                        height: 28px;
                        background-color: white;
                        position: absolute;
                        top: 1px;
                        left: 1px;
                        border-radius: 28px;
                        transition: left 0.2s;;
                    }
                }

                &:checked + span.switch {
                    background-color: $color-green;
                    &:before {
                        left: 31px;
                    }
                }
            }
        }
    }

    .error-msj {
        color: $color-red;
        position: absolute;
        top: 100%;
        margin: 0;
        padding-top: 0.1rem;
        display: none;
        font-size: 0.8rem;
        line-height: 0.9rem;
        &.l-fixed {
            display: block!important;
        }
    }

    .ng-invalid.ng-touched + .error-msj:not(.l-fixed) {
        display: block;
    }

    // &.ng-submitted {
    // }
    .input.ng-touched.ng-invalid {
        border-color: $color-red;
        .ng-select-container {
            border-color: $color-red;
        }
    }
    
    .checkbox {
        input[type=checkbox].ng-touched.ng-invalid + span.checkbox {
            box-shadow: inset 0px 0px 0px 1px $color-red;
            border-radius: 3px;
        }
    }
    
    .radio {
        input[type=radio].ng-touched.ng-invalid + span.radio {
            box-shadow: inset 0px 0px 0px 999px $color-red;
            border-radius: 3px;
        }
    }
}

input[type=radio],
input[type=checkbox] {
    accent-color: $color-green;
}