.btn {
    display: inline-block;
    border-radius: 4px;
    background-color: $color-green;
    padding: 17px;
    color: #FFFFFF;
    font-family: Geometria;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.09px;
    line-height: 14px;
    text-align: center;
    outline: none;
    border: none;
    cursor: pointer;
    transition: opacity 0.2s;
    text-decoration: none;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

    &:not(:disabled) {
    
        &:hover {
            opacity: 0.7;
        }
    
        &:active {
            opacity: 0.4;
        }

    }

    &:disabled {
        background-color: $grey-4;
        color: $grey-2;
        cursor: not-allowed;
    }
    
    @media screen and (min-width: $breakpoint-min-tablet) {
        padding: 21px;
    }

    
    &-outline {
        background-color: transparent;
        box-shadow: inset 0 0 0  2px $color-green;
        color: $color-green;
        &:disabled {
            box-shadow: inset 0 0 0  2px $grey-4;
            color: $grey-2;
        }
    }

}