.page-modal {
    h1 {
        @extend .heading-1;
    }
    h2 {
        @extend .heading-2;
    }
    h3 {
        @extend .heading-3;
    }
    h4 {
        @extend .heading-4;
    }
    h5 {
        @extend .heading-5;
    }
    ol {
        list-style-type: decimal;
        margin-top: 12px;
    }
    li {
        @extend .text-2;
        list-style-type: decimal;
        margin-bottom: 12px;
    }
    p {
        @extend .text-2;
        margin-bottom: 12px;
    }
}
