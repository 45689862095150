.notice {
    padding: 18px 18px 16px 48px;
    position: relative;
    color: $color-dark;
    font-family: Montserrat;
    font-size: 14px;
    letter-spacing: -0.1px;
    line-height: 20px;
    border-radius: 4px;
    
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 16px;
        left: 16px;
        width: 24px;
        height: 24px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &.info {
        background-color: #E0F0FA;
        &:before {
            background-image: url(../../assets/icon/notice-info.svg);
        }
    }

    &.warning{
        background-color: #FFEFCA;
        &:before {
            background-image: url(../../assets/icon/notice-warning.svg);
        }
    }

    &.error {
        background-color: #FBCCDA;
        &:before {
            background-image: url(../../assets/icon/notice-error.svg);
        }
    }

    &.success {
        background-color: #CDF2DC;
        &:before {
            background-image: url(../../assets/icon/notice-check.svg);
        }
    }
}