@font-face {
  font-family: "Geometria";
  src: url("../../assets/fonts/geometria/Geometria.woff2") format("woff2"), url("../../assets/fonts/geometria/Geometria.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Geometria";
  src: url("../../assets/fonts/geometria/Geometria-Bold.woff2") format("woff2"), url("../../assets/fonts/geometria/Geometria-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Geometria";
  src: url("../../assets/fonts/geometria/Geometria-ExtraBold.woff2") format("woff2"), url("../../assets/fonts/geometria/Geometria-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Geometria";
  src: url("../../assets/fonts/geometria/Geometria-ExtraLight.woff2") format("woff2"), url("../../assets/fonts/geometria/Geometria-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Geometria";
  src: url("../../assets/fonts/geometria/Geometria-Heavy.woff2") format("woff2"), url("../../assets/fonts/geometria/Geometria-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Geometria";
  src: url("../../assets/fonts/geometria/Geometria-Light.woff2") format("woff2"), url("../../assets/fonts/geometria/Geometria-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Geometria";
  src: url("../../assets/fonts/geometria/Geometria-Medium.woff2") format("woff2"), url("../../assets/fonts/geometria/Geometria-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Geometria";
  src: url("../../assets/fonts/geometria/Geometria-Thin.woff2") format("woff2"), url("../../assets/fonts/geometria/Geometria-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/montserrat/Montserrat-Black.woff2") format("woff2"), url("../../assets/fonts/montserrat/Montserrat-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/montserrat/Montserrat-Bold.woff2") format("woff2"), url("../../assets/fonts/montserrat/Montserrat-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/montserrat/Montserrat-ExtraBold.woff2") format("woff2"), url("../../assets/fonts/montserrat/Montserrat-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/montserrat/Montserrat-ExtraLight.woff2") format("woff2"), url("../../assets/fonts/montserrat/Montserrat-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/montserrat/Montserrat-Light.woff2") format("woff2"), url("../../assets/fonts/montserrat/Montserrat-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/montserrat/Montserrat-Medium.woff2") format("woff2"), url("../../assets/fonts/montserrat/Montserrat-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/montserrat/Montserrat-Regular.woff2") format("woff2"), url("../../assets/fonts/montserrat/Montserrat-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/montserrat/Montserrat-SemiBold.woff2") format("woff2"), url("../../assets/fonts/montserrat/Montserrat-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/montserrat/Montserrat-Thin.woff2") format("woff2"), url("../../assets/fonts/montserrat/Montserrat-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
*, *:before, *:after, *::before, *::after {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

[hidden] {
  display: none !important;
}

.debug {
  padding: 4px;
  border: 2px dashed yellow;
  background-color: grey;
  position: fixed;
  opacity: 0.5;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
}

.page {
  display: block;
}

.heading-1, .page-modal h1 {
  font-family: "Geometria", sans-serif;
  font-size: 40px;
  font-weight: 300;
  letter-spacing: -3px;
  line-height: 44px;
}

.heading-2, .page-modal h2 {
  color: #0F191E;
  font-family: "Geometria", sans-serif;
  font-size: 26px;
  font-weight: 300;
  letter-spacing: -0.3px;
  line-height: 30px;
}
.heading-2 strong, .page-modal h2 strong {
  font-weight: 500;
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .heading-2, .page-modal h2 {
    font-size: 36px;
    line-height: 40px;
    letter-spacing: -2px;
  }
}
@media screen and (min-width: 1280px) {
  .heading-2, .page-modal h2 {
    font-size: 40px;
    line-height: 44px;
    letter-spacing: -3px;
  }
}

.heading-3, .page-modal h3 {
  font-family: "Geometria", sans-serif;
  font-size: 24px;
  letter-spacing: -0.4px;
  line-height: 30px;
  text-align: center;
}
.heading-3 strong, .page-modal h3 strong {
  font-weight: 500;
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .heading-3, .page-modal h3 {
    font-size: 28px;
    line-height: 32px;
    letter-spacing: -1px;
  }
}
@media screen and (min-width: 1280px) {
  .heading-3, .page-modal h3 {
    font-size: 32px;
    line-height: 36px;
    letter-spacing: -1px;
  }
}

.heading-4, .page-modal h4 {
  font-family: "Geometria", sans-serif;
  font-size: 20px;
  letter-spacing: -0.4px;
  line-height: 25px;
  text-align: center;
}
.heading-4 strong, .page-modal h4 strong {
  font-weight: 500;
}

.heading-5, .page-modal h5 {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  letter-spacing: -0.4px;
  line-height: 18px;
}
.heading-5 strong, .page-modal h5 strong {
  font-weight: 500;
}

.text-1 {
  display: block;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;
  font-family: "Montserrat";
  font-weight: 400;
  color: #0F191E;
  text-align: center;
}
.text-1 strong {
  font-weight: 600;
}
@media screen and (min-width: 768px) {
  .text-1 {
    font-size: 18px;
    line-height: 28px;
  }
}

.text-2, .page-modal p, .page-modal li {
  display: block;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.1px;
  font-family: "Montserrat";
  font-weight: 400;
  color: #0F191E;
}
.text-2 strong, .page-modal p strong, .page-modal li strong {
  font-weight: 600;
}
@media screen and (min-width: 768px) {
  .text-2, .page-modal p, .page-modal li {
    font-size: 18px;
    line-height: 28px;
  }
}

.text-3 {
  display: block;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.1px;
  font-family: "Montserrat";
  font-weight: 400;
  color: #0F191E;
}
.text-3 strong {
  font-weight: 600;
}

.weight-regular {
  font-weight: normal;
}

.weight-bold {
  font-weight: bold;
}

.weight-extra_bold {
  font-weight: bold;
}

.weight-extra_light {
  font-weight: 200;
}

.weight-heavy {
  font-weight: 900;
}

.weight-light {
  font-weight: 300;
}

.weight-medium2 {
  font-weight: 600;
}

.weight-medium {
  font-weight: 500;
}

.weight-thin {
  font-weight: 100;
}

.color-green {
  color: #05BE50;
}

.color-blue {
  color: #0039A6;
}

.color-light {
  color: #fff;
}

.color-dark {
  color: #0F191E;
}

.no-text-decoration {
  text-decoration: none;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

ul.list {
  margin-bottom: 24px;
}
ul.list li {
  display: block;
  position: relative;
  padding-left: 24px;
  margin-bottom: 22px;
  color: #0F191E;
  font-family: Montserrat;
  font-size: 14px;
  letter-spacing: -0.1px;
  line-height: 20px;
}
ul.list li:before {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 3px;
  width: 10px;
  height: 9px;
  background-image: url(../../assets/icon/list-check.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
ul.list li strong {
  font-weight: 600;
}
@media screen and (min-width: 768px) {
  ul.list {
    margin-bottom: 20px;
  }
  ul.list li {
    padding-left: 32px;
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 24px;
  }
  ul.list li:before {
    top: 4px;
    left: 6px;
    width: 15px;
    height: 12px;
  }
}
@media screen and (min-width: 1280px) {
  ul.list {
    margin-bottom: 28px;
  }
  ul.list li {
    padding-left: 32px;
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 24px;
  }
  ul.list li:before {
    top: 4px;
    left: 6px;
    width: 15px;
    height: 12px;
  }
}

@media screen and (max-width: 767px) {
  span.break--mobile {
    display: block;
  }
  .text-left--mobile {
    text-align: left !important;
  }
  .text-center--mobile {
    text-align: center !important;
  }
  .text-right--mobile {
    text-align: right !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  span.break--tablet {
    display: block;
  }
  .text-left--tablet {
    text-align: left !important;
  }
  .text-center--tablet {
    text-align: center !important;
  }
  .text-right--tablet {
    text-align: right !important;
  }
}
@media screen and (min-width: 1280px) {
  span.break--desktop {
    display: block;
  }
  .text-left--desktop {
    text-align: left !important;
  }
  .text-center--desktop {
    text-align: center !important;
  }
  .text-right--desktop {
    text-align: right !important;
  }
}
.d-block {
  display: block;
}

.full-width {
  width: 100%;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.center-block {
  display: block !important;
  margin-left: auto;
  margin-right: auto;
}

.container {
  width: 100%;
  max-width: 1107px;
  margin-right: auto;
  margin-left: auto;
}

.text-legales {
  padding: 20px 24px;
  text-align: center;
}
.text-legales small {
  font-family: "Montserrat";
  font-size: 11px;
  line-height: 16px;
  color: #0F191E;
  text-align: center;
}

hr.separator {
  display: block;
  width: 100%;
  max-width: 72px;
  margin: auto;
  border-color: #ECEDED;
  border-bottom: 0;
  opacity: 0.5;
}

.megahidden {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -33333333;
  width: 1px;
  height: 1px;
  opacity: 0;
  visibility: hidden;
}

.promotion-ticket {
  padding: 3rem 2rem;
  position: relative;
}
.promotion-ticket p.title {
  text-align: center;
  color: #0F191E;
}
.promotion-ticket p.coupon {
  text-align: center;
  color: #0039A6;
}
.promotion-ticket:before {
  content: "";
  display: block;
  background: #878C8F;
  width: 100%;
  max-width: 5rem;
  height: 1px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.promotion-ticket:after {
  content: "";
  display: block;
  background: #878C8F;
  width: 100%;
  max-width: 5rem;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.promotion-ticket-fancy {
  padding: 20px 24px;
  display: block;
  background-color: rgba(217, 218, 219, 0.33);
  position: relative;
  width: 100%;
  max-width: 408px;
}
.promotion-ticket-fancy p.title {
  color: #0F191E;
  text-align: center;
  margin-bottom: 4px !important;
}
.promotion-ticket-fancy p.coupon {
  color: #0039A6;
  text-align: center;
  margin-bottom: 0 !important;
}
.promotion-ticket-fancy:before {
  content: "";
  display: block;
  position: absolute;
  top: -10px;
  width: 100%;
  height: 10px;
  background-image: url(../../assets/spikes.png);
  background-repeat: repeat no-repeat;
  left: 0;
}
.promotion-ticket-fancy:after {
  content: "";
  display: block;
  position: absolute;
  bottom: -10px;
  width: 100%;
  height: 10px;
  background-image: url(../../assets/spikes.png);
  background-repeat: repeat no-repeat;
  background-position: center;
  left: 0;
  transform: rotateX(180deg);
}

@media screen and (min-width: 1280px) {
  .d-full-vh {
    height: calc(100vh - 64px);
  }
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .t-full-vh {
    height: calc(100vh - 64px);
  }
}
@media screen and (max-width: 768px) {
  .center-block--mobile {
    display: block !important;
    margin-left: auto;
    margin-right: auto;
  }
  .fw--mobile {
    display: block;
    width: 100%;
  }
}
@media screen and (min-width: 769px) and (max-width: 1280px) {
  .center-block--tablet {
    display: block !important;
    margin-left: auto;
    margin-right: auto;
  }
  .fw--tablet {
    display: block;
    width: 100%;
  }
}
@media screen and (min-width: 1281px) {
  .center-block--desktop {
    display: block !important;
    margin-left: auto;
    margin-right: auto;
  }
  .fw--desktop {
    display: block;
    width: 100%;
  }
}
.ng-option span {
  color: #0F191E;
  font-family: Geometria;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.1px;
}

.form label {
  display: block;
  position: relative;
}
.form label > span {
  display: block;
  font-family: "Geometria";
  color: #878C8F;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 8px;
  letter-spacing: -0.1px;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .form label > span {
    font-size: 16px;
    line-height: 20px;
  }
}
.form label input.input {
  display: block;
  border: 1px solid #D9DADB;
  border-radius: 4px;
  background-color: #fff;
  height: 44px;
  width: 100%;
  padding: 0 8px;
  color: #0F191E;
  font-family: Geometria;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.1px;
}
@media screen and (min-width: 768px) {
  .form label input.input {
    height: 48px;
  }
}
.form label .ng-select.input {
  display: block;
}
.form label .ng-select.input .ng-select-container {
  height: 44px;
  width: 100%;
  border: 1px solid #D9DADB;
  border-radius: 4px;
}
@media screen and (min-width: 768px) {
  .form label .ng-select.input .ng-select-container {
    height: 48px;
  }
}
.form label .ng-select.input .ng-placeholder {
  color: #B7BABC;
  font-family: Geometria;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.1px;
  line-height: 18px;
}
.form label .ng-select.input .ng-input {
  top: 50% !important;
  transform: translateY(-50%);
}
.form label .ng-select.input span {
  margin-bottom: 0 !important;
}
.form label .ng-select.input .ng-value-label {
  color: #0F191E;
  font-family: Geometria;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.1px;
}
.form label .ng-select.input .ng-arrow-wrapper {
  padding-right: 20px;
  box-sizing: content-box;
  text-align: right;
}
.form label .ng-select.input .ng-arrow-wrapper .ng-arrow {
  width: 16px;
  height: 16px;
  border: none;
  top: 4px;
  margin-left: auto;
  margin-right: 0;
  left: unset;
  background-image: url(../../assets/icon/form/select-caret.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.form label .ng-select.input.ng-select-opened .ng-arrow {
  transform: rotate(-180deg);
}
.form label.checkbox {
  position: relative;
  padding-left: 24px;
}
.form label.checkbox:after {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
  content: url(../../assets/icon/form/checkbox-off.svg) url(../../assets/icon/form/checkbox-on.svg) url(../../assets/icon/form/checkbox-error.svg);
}
.form label.checkbox input[type=checkbox] {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}
.form label.checkbox input[type=checkbox] + span.checkbox {
  display: block;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  position: absolute;
  background-image: url(../../assets/icon/form/checkbox-off.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.form label.checkbox input[type=checkbox]:checked + span.checkbox {
  background-image: url(../../assets/icon/form/checkbox-on.svg);
}
.form label.checkbox p {
  color: #0F191E;
  font-family: Montserrat;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
}
.form label.radio {
  position: relative;
  padding-left: 24px;
}
.form label.radio:after {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
  content: url(../../assets/icon/form/radio-off.svg) url(../../assets/icon/form/radio-on.svg) url(../../assets/icon/form/radio-error.svg);
}
.form label.radio input[type=radio] {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}
.form label.radio input[type=radio] + span.radio {
  display: block;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  position: absolute;
  background-image: url(../../assets/icon/form/radio-off.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.form label.radio input[type=radio]:checked + span.radio {
  background-image: url(../../assets/icon/form/radio-on.svg);
}
.form label.radio p {
  color: #0F191E;
  font-family: Montserrat;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
}
.form label.big-switch {
  position: relative;
  padding-right: 72px;
}
.form label.big-switch input[type=checkbox] {
  display: block;
  width: 1px;
  height: 1px;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
.form label.big-switch input[type=checkbox] + span.switch {
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 60px;
  height: 30px;
  background-color: #ECEDED;
  border-radius: 60px;
  transition: background-color 0.2s;
}
.form label.big-switch input[type=checkbox] + span.switch:before {
  content: "";
  display: block;
  width: 28px;
  height: 28px;
  background-color: white;
  position: absolute;
  top: 1px;
  left: 1px;
  border-radius: 28px;
  transition: left 0.2s;
}
.form label.big-switch input[type=checkbox]:checked + span.switch {
  background-color: #05BE50;
}
.form label.big-switch input[type=checkbox]:checked + span.switch:before {
  left: 31px;
}
.form .error-msj {
  color: #EB0046;
  position: absolute;
  top: 100%;
  margin: 0;
  padding-top: 0.1rem;
  display: none;
  font-size: 0.8rem;
  line-height: 0.9rem;
}
.form .error-msj.l-fixed {
  display: block !important;
}
.form .ng-invalid.ng-touched + .error-msj:not(.l-fixed) {
  display: block;
}
.form .input.ng-touched.ng-invalid {
  border-color: #EB0046;
}
.form .input.ng-touched.ng-invalid .ng-select-container {
  border-color: #EB0046;
}
.form .checkbox input[type=checkbox].ng-touched.ng-invalid + span.checkbox {
  box-shadow: inset 0px 0px 0px 1px #EB0046;
  border-radius: 3px;
}
.form .radio input[type=radio].ng-touched.ng-invalid + span.radio {
  box-shadow: inset 0px 0px 0px 999px #EB0046;
  border-radius: 3px;
}

input[type=radio],
input[type=checkbox] {
  accent-color: #05BE50;
}

.btn {
  display: inline-block;
  border-radius: 4px;
  background-color: #05BE50;
  padding: 17px;
  color: #FFFFFF;
  font-family: Geometria;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.09px;
  line-height: 14px;
  text-align: center;
  outline: none;
  border: none;
  cursor: pointer;
  transition: opacity 0.2s;
  text-decoration: none;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.btn:not(:disabled):hover {
  opacity: 0.7;
}
.btn:not(:disabled):active {
  opacity: 0.4;
}
.btn:disabled {
  background-color: #ECEDED;
  color: #B7BABC;
  cursor: not-allowed;
}
@media screen and (min-width: 768px) {
  .btn {
    padding: 21px;
  }
}
.btn-outline {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #05BE50;
  color: #05BE50;
}
.btn-outline:disabled {
  box-shadow: inset 0 0 0 2px #ECEDED;
  color: #B7BABC;
}

.notice {
  padding: 18px 18px 16px 48px;
  position: relative;
  color: #0F191E;
  font-family: Montserrat;
  font-size: 14px;
  letter-spacing: -0.1px;
  line-height: 20px;
  border-radius: 4px;
}
.notice:before {
  content: "";
  display: block;
  position: absolute;
  top: 16px;
  left: 16px;
  width: 24px;
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.notice.info {
  background-color: #E0F0FA;
}
.notice.info:before {
  background-image: url(../../assets/icon/notice-info.svg);
}
.notice.warning {
  background-color: #FFEFCA;
}
.notice.warning:before {
  background-image: url(../../assets/icon/notice-warning.svg);
}
.notice.error {
  background-color: #FBCCDA;
}
.notice.error:before {
  background-image: url(../../assets/icon/notice-error.svg);
}
.notice.success {
  background-color: #CDF2DC;
}
.notice.success:before {
  background-image: url(../../assets/icon/notice-check.svg);
}

.mb-050 {
  margin-bottom: 0.5rem !important;
}

.mb-075 {
  margin-bottom: 0.75rem !important;
}

.mb-100 {
  margin-bottom: 1rem !important;
}

.mb-125 {
  margin-bottom: 1.25rem !important;
}

.mb-225 {
  margin-bottom: 2.25rem !important;
}

.mt-050 {
  margin-top: 0.5rem !important;
}

.mt-075 {
  margin-top: 0.75rem !important;
}

.mt-100 {
  margin-top: 1rem !important;
}

.mt-125 {
  margin-top: 1.25rem !important;
}

.pr-125 {
  padding-right: 1.25rem !important;
}

.page-modal ol {
  list-style-type: decimal;
  margin-top: 12px;
}
.page-modal li {
  list-style-type: decimal;
  margin-bottom: 12px;
}
.page-modal p {
  margin-bottom: 12px;
}