.d-block {
    display: block;
}

.full-width {
    width: 100%;
}
.absolute {
    position: absolute;
}
.relative{
    position: relative;
}
.center-block {
    display: block!important;
    margin-left: auto;
    margin-right: auto;
}

.container {
    width: 100%;
    max-width: 1107px;
    margin-right: auto;
    margin-left: auto;
}


.text-legales {
    padding: 20px 24px;
    text-align: center;
    small {
        font-family: 'Montserrat';
        font-size: 11px;
        line-height: 16px;
        color: $color-dark;
        text-align: center;
    }
}

hr.separator {
    display: block;
    width: 100%;
    max-width: 72px;
    margin: auto;
    border-color: $grey-4;
    border-bottom: 0;
    opacity: 0.5;
}

.megahidden {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -33333333;
    width: 1px;
    height: 1px;
    opacity: 0;
    visibility: hidden;
}

.promotion-ticket {
    padding: 3rem 2rem;
    position: relative;
    p.title {
        text-align: center;
        color: $color-dark;
    }
    p.coupon {
        text-align: center;
        color: $color-blue;
    }
    &:before {
        content: '';
        display: block;
        background: $color-gray-dark;
        width: 100%;
        max-width: 5rem;
        height: 1px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }
    &:after {
        content: '';
        display: block;
        background: $color-gray-dark;
        width: 100%;
        max-width: 5rem;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}

.promotion-ticket-fancy {
    padding: 20px 24px;
    display: block;
    background-color: rgba($grey-3, 0.33);
    position: relative;
    width: 100%;
    max-width: 408px;

    p.title {
        color: $color-dark;
        text-align: center;
        margin-bottom: 4px!important;
    }

    p.coupon {
        color: $color-blue;
        text-align: center;
        margin-bottom: 0!important;
    }

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: -10px;
        width: 100%;
        height: 10px;
        background-image: url(../../assets/spikes.png);
        background-repeat: repeat no-repeat;
        left: 0;
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: -10px;
        width: 100%;
        height: 10px;
        background-image: url(../../assets/spikes.png);
        background-repeat: repeat no-repeat;
        background-position: center;
        left: 0;
        transform: rotateX(180deg);
    }
}

@media screen and (min-width: $breakpoint-min-desktop) {
    .d-full-vh {
        height: calc(100vh - 64px);
    }
}

@media screen and (min-width: $breakpoint-min-tablet) and (max-width: ($breakpoint-min-desktop - 1px)) {
    .t-full-vh {
        height: calc(100vh - 64px);
    }
}
    
@media screen and (max-width: $breakpoint-min-tablet) {
    .center-block--mobile {
        display: block!important;
        margin-left: auto;
        margin-right: auto;
    }

    .fw--mobile {
        display: block;
        width: 100%;
    }
}

@media screen and (min-width: ($breakpoint-min-tablet + 1px)) and (max-width: $breakpoint-min-desktop) {
    .center-block--tablet {
        display: block!important;
        margin-left: auto;
        margin-right: auto;
    }

    .fw--tablet {
        display: block;
        width: 100%;
    }
}

@media screen and (min-width: ($breakpoint-min-desktop + 1px)) {
    .center-block--desktop {
        display: block!important;
        margin-left: auto;
        margin-right: auto;
    }

    .fw--desktop {
        display: block;
        width: 100%;
    }
}