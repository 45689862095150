$color-green: #05BE50;
$color-blue: #0039A6;
$color-blue-light: #64B4E6;

$color-light: #fff;
$color-dark: #0F191E;

$color-gray-dark: #878C8F;
$color-gray-light: #FBFBFB;

$color-red: #EB0046;

$grey-1: #404040;
$grey-2: #B7BABC;
$grey-3: #D9DADB;
$grey-4: #ECEDED;